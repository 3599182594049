(function(){
  const burger = document.getElementById('jsBurger');
  // ない場合はなにもしない
  if( ! burger ) return;

  // 付与するクラス名
  const changeClassName = 'visible-mobile-menu',
        waiAriaPressed  = 'aria-pressed',
        closeBtn        = document.getElementById('closeMenu'),
        navCover        = document.querySelector('.nav-cover');

  // クラスの付替
  const changeClass = (bool) => {
    if(bool === 'true') {
      document.body.classList.add(changeClassName);
    } else {
      document.body.classList.remove(changeClassName);
    }
  };

  burger.onclick = (e) => {
    // burger が押されているかを確認します
    const pressed = e.target.getAttribute(waiAriaPressed) === 'true';
    // burger の aria-pressed を反対の状態に変更します
    burger.setAttribute(waiAriaPressed, !pressed);
    // burger の状態をチェックしてクラスを付け替える
    changeClass( burger.getAttribute(waiAriaPressed) );
  };

  closeBtn.onclick = (e) => {
    // burger の aria-pressed を false の状態に変更します
    burger.setAttribute(waiAriaPressed, false);
    // burger の状態をチェックしてクラスを付け替える
    changeClass( burger.getAttribute(waiAriaPressed) );
  };

  navCover.onclick = (e) => {
    // burger の aria-pressed を false の状態に変更します
    burger.setAttribute(waiAriaPressed, false);
    // burger の状態をチェックしてクラスを付け替える
    changeClass( burger.getAttribute(waiAriaPressed) );
  };
  
  
  //test
  /*const fontsize = document.getElementById('switch');
  fontsize.onclick = (e) => {
    // fontsize が押されているかを確認します
    const pressed = e.target.getAttribute(waiAriaPressed) === 'true';
    // fontsize の aria-pressed を反対の状態に変更します
    fontsize.setAttribute(waiAriaPressed, !pressed);
    // fontsize の状態をチェックしてクラスを付け替える
    changeClass( fontsize.getAttribute(waiAriaPressed) );
  };*/
  
  
  
}());
