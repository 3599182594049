(function(){

  const loadingWrapper = document.getElementById('loadingWrapper');

  // ない場合はなにもしない
  if( ! loadingWrapper ) return;

  window.onload = function(){
    setTimeout(function() {
      loadingWrapper.classList.add('disabled');
    }, 100);
  };

}());
