(function(){

  // タブの親要素
  const parentTabs  = document.querySelectorAll('.jsTab');

  // ない場合はなにもしない
  if( parentTabs.length === 0 ) return;

  // 指定した親要素をループ
  for( const tab of parentTabs ) {

    // タブのラベル要素
    const tabs = tab.querySelectorAll('[role="tab"]');

    // ラベルのクリックイベント
    tabs.forEach((item) => {
      item.onclick = (e) => {
        const target = e.target;
        const parent = target.parentNode.parentNode;
        const grandparent = parent.parentNode;
        // タブから現在すべての選択状態を取り除きます
        parent
          .querySelectorAll('[aria-selected="true"]')
          .forEach((t) => t.setAttribute('aria-selected', false));

        // このタブを選択されたタブとして設定します
        target.setAttribute('aria-selected', true);

        // すべてのタブパネルを非表示にします
        grandparent
          .querySelectorAll('[role="tabpanel"]')
          .forEach((p) => p.setAttribute('hidden', true));

        // 選択されたパネルを表示します
        grandparent.parentNode
          .querySelector(`#${target.getAttribute('aria-controls')}`)
          .removeAttribute('hidden');
      };
    }); // ラベルのクリックイベント

  } // 指定した親要素をループ

  return;



  // タブ処理を行う要素のクラス名
  const tabsClass            = 'js-tab';
  const currentClassName     = 'js-tab-current';
  const currentViewClassName = 'js-tab-current-view';

  // タブ処理を行う要素の取得
  //const tabs = document.getElementsByClassName(tabsClass);



  // Click event
  for (let i = 0; i < tabs.length; i++){
    tabs[i].addEventListener('click', function(){
      // データ属性を取得する
      const view = this.dataset.view;
      // .current が付与しているかチェック
      const isCurrent = this.classList.contains(currentClassName);
      // クリックしたタブの親要素を取得する
      const tabsParent = this.parentNode.parentNode.parentNode;
      // 他のタブ要素に影響しないようにクリックしたタブ要素だけに絞る
      const tabsChild = tabsParent.getElementsByClassName(tabsClass);
      // コンテンツの要素を取得する
      const allContents = tabsParent.getElementsByClassName('js-contents');

      if (!isCurrent) {
        // remove .current
        for ( let j = 0; j < tabsChild.length; j++ ){
          tabsChild[j].classList.remove(currentClassName);
        };
        this.classList.add(currentClassName);
      };
      // remove .current-view
      for ( let k = 0; k < allContents.length; k++ ){
        allContents[k].classList.remove(currentViewClassName);
      };
      //
      document.getElementById(view).classList.add(currentViewClassName);
    });
  };
}());



function changeTabs(e)
{
  const target = e.target;
  const parent = target.parentNode;
  const grandparent = parent.parentNode;

  // タブから現在すべての選択状態を取り除きます

}
