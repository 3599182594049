import { getStyle } from './export';



(function(){

  // アコーディオンの親要素
  const parentAccordions  = document.querySelectorAll('.jsAccordion');

  // ない場合はなにもしない
  if( parentAccordions.length === 0 ) return;

  // 指定した親要素をループ
  for( const accordion of parentAccordions ) {

    // アコーディオンのトリガーをすべて取得する
    const triggers = accordion.querySelectorAll('[role="tree"]');

    // トリガーのクリックイベント
    triggers.forEach((item) => {
      item.onclick = (e) => {
        const target = e.target;
        // トリガーが押されているかを確認します
        const pressed = target.getAttribute('aria-pressed') === 'true';
        // 開閉する要素
        const panel = target.nextElementSibling;
        // トリガーから現在すべての選択状態を取り除きます
        triggers.forEach((t) => t.setAttribute('aria-expanded', false));
        // このトリガーを選択されたタブとして設定します
        target.setAttribute('aria-expanded', true);
        target.setAttribute('aria-pressed', !pressed);

        // 開閉する要素の高さを取得します
        const maxHeightPanel = getStyle( panel, 'maxHeight' );
        if (maxHeightPanel !== '0px') {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = panel.scrollHeight + 'px';
        };
      };
    });
  };

  return;
}());
