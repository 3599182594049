import 'scroll-behavior-polyfill';
import './lib/burger';
import './lib/pageAnchor';
import './lib/accordion';
import './lib/tab';
import './lib/pagetop';
import './lib/loading';
import './lib/globalMenu';





/**
***  header  */
window.addEventListener('load', () => {
  // フォントサイズ変更
  (function(){
    //test
    const waiAriaPressed  = 'aria-checked',
          //changeClassfonts = 'fonts',
          fontsize = document.getElementById('switch');

    // ない場合はなにもしない
    if( ! fontsize ) return;

    const changeClass = (bool) => {
      if(bool === 'true') {
        //document.body.parentNode.classList.add(changeClassfonts);
        document.body.parentNode.style.fontSize = '80%';
      } else {
        //document.body.parentNode.classList.remove(changeClassfonts);
        document.body.parentNode.style.fontSize = "";
      }
    };

    fontsize.onclick = (e) => {
      // fontsize が押されているかを確認します
      const pressed = e.target.getAttribute(waiAriaPressed) === 'true';
      // fontsize の aria-pressed を反対の状態に変更します
      fontsize.setAttribute(waiAriaPressed, !pressed);
      // fontsize の状態をチェックしてクラスを付け替える
      changeClass( fontsize.getAttribute(waiAriaPressed) );
    };
  }());

  // header の表示制御
  (function(){
    const header = document.getElementById('header');
    // headerのY座標の初期値を変数で指定
    let startPosition = 0;
    // headerのY座標の現在値を変数で指定
    let NowPosition = 0;
    window.addEventListener('scroll', () => {
      // headerのY座標の現在値を更新
      NowPosition = window.scrollY;

      if(NowPosition >= startPosition && window.scrollY >= 200) {
          header.classList.add('remove');
      } else {
          header.classList.remove('remove');
      }
      // 初期値を更新
      startPosition = NowPosition;
  });
  }());

});






/**
***  旧サイトから移行  */

// サイドバー
(function(){
  const sidebar = document.querySelectorAll('.sidebar');
  const target = document.querySelector('.sidebar li a[href^="' + location.pathname + '"]');
  if( !sidebar.length || !target ) return;
  target.closest('li').classList.add('on');
}());



// アコーディオン
(function(){
  $('.accordion-heading').click(function(){
  $(this).toggleClass('on').next('.accordion-content').stop().slideToggle();
  return false;
});
}());



// ポップアップ
$(function() {
  $('.popup-modal').magnificPopup({
    type: 'inline',
    midClick: true,
    mainClass: 'mfp-fade',
    removalDelay: 150,
    showCloseBtn: false,
  });
  $('.gnav-popup-close').click(function(){
    $.magnificPopup.close();
    return false;
  });

  $('.over').on('mouseenter', function(e) {
    var parentOffset = $(this).offset(),
        relX = e.pageX - parentOffset.left,
        relY = e.pageY - parentOffset.top;
    $(this).find('.cover').css({top:relY, left:relX})
  }).on('mouseout', function(e) {
    var parentOffset = $(this).offset(),
        relX = e.pageX - parentOffset.left,
        relY = e.pageY - parentOffset.top;
    $(this).find('.cover').css({top:relY, left:relX})
  });

  $('.gnav-popup-list li > a').click(function(){
    $(this).toggleClass('on').next().stop().slideToggle();
  });

  $(".gnav-popup-scroller").mCustomScrollbar();
});
