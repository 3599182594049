import { getStyle } from './export';

(function(){

  // グローバルナビゲーションの要素
  const globalNav = document.getElementById('globalNav');

  // すべてのトリガーを取得する
  const allTrigger = globalNav.querySelectorAll('.js-trigger');

  // 幅が lg 以上のビューポートをターゲットとするメディアクエリを作成
  const mediaQuery = window.matchMedia('(min-width: 992px)');

  // アクティブクラスが付いているかチェックする
  const activeClassName = 'active';

  // ホバー時に付与するクラス名
  const hoverClassName = 'hover';

  // Window width
  let memoryWindowWidth = window.innerWidth;


  // イベント
  allTrigger.forEach( elm => {
    // クリックイベント
    elm.addEventListener('click', function(t){
      // PCでの処理
      if( mediaQuery.matches ){
        return;
      } else {
        // モバイルでの処理
        t.preventDefault();
        // アクティブ判定
        const isActive = this.parentNode.classList.contains(activeClassName);

        // 一度、すべてのメニューを閉じる
        allTrigger.forEach( child => {
          // 高さを取得する要素（クリックした要素の子メニュー）
          const panel = child.nextElementSibling;
          //const maxHeightValue = getStyle(panel, 'maxHeight');
          if( child !== this ){
            child.parentNode.classList.remove(activeClassName);
            panel.style.maxHeight = null;
          }
        });
        // アクティブクラスの処理
        isActive ? this.parentNode.classList.remove(activeClassName) : this.parentNode.classList.add(activeClassName);
        // 子メニューの開閉処理
        // Toggle the panel element
        const panel = this.nextElementSibling;
        const maxHeightValue = getStyle( panel, 'maxHeight' );
        if (maxHeightValue !== '0px') {
          panel.style.maxHeight = null;
          return;
        }
        panel.style.maxHeight = panel.scrollHeight + 'px';
      }
    });

    // PC時のホバー処理
    elm.parentNode.addEventListener( 'mouseenter', () => {
      if( mediaQuery.matches ){
        elm.parentNode.classList.add( hoverClassName );
        elm.nextElementSibling.style.maxHeight = elm.nextElementSibling.scrollHeight + 'px';
      }
    });
    elm.parentNode.addEventListener( 'mouseleave', () => {
      if( mediaQuery.matches ){
        elm.parentNode.classList.remove( hoverClassName );
        elm.nextElementSibling.style.maxHeight = null;
      }
    });

  });
  // window リサイズでメニューをクリアにする処理
  window.addEventListener( 'resize', function(){
    if( memoryWindowWidth !== window.innerWidth ){
      // window の width を取得し直す
      memoryWindowWidth = window.innerWidth;
      allTrigger.forEach( el => {
        // 子メニューを閉じる
        el.parentNode.classList.remove(activeClassName);
        const panel = el.nextElementSibling;
        panel.style.maxHeight = null;
      });
      // バーガーを閉じる
      this.document.body.classList.remove('visible-mobile-menu');
    }
  });

}());
