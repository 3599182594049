(function(){

  const elm = document.querySelectorAll('.js-page-anchor');

  // ない場合はなにもしない
  if( ! elm.length ) return;

  // イベント
  for ( let i = 0; i < elm.length; i++) {
    elm[i].addEventListener( 'click', function(e){
      e.preventDefault();
      const href = this.getAttribute('href');
      // ページ内リンクでない場合は何もしない
      if( href.slice(0, 1) != '#' ){
        return;
      };
      // リンク先の要素を取得
      const pageID = document.getElementById(href.slice(1));
      // ない場合はなにもしない
      if( !pageID ){ return; };
      const rect = pageID.getBoundingClientRect();
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const position = rect.top + scrollTop;
      // スクロール処理
      window.scrollTo({
        top: position - 16,
        left: 0,
        behavior: 'smooth'
      });
    });
  };

}());


//ページ遷移後のアンカーリンクへのスクロール
// 固定ヘッダー（固定しない場合は = 0）
const headerHeight = document.querySelector('header').offsetHeight;

// イージング関数（easeOutExpo）
function scrollToPos(position) {
  const startPos = window.scrollY;
  const distance = Math.min(position - startPos, document.documentElement.scrollHeight - window.innerHeight - startPos);
  const duration = 800; // スクロールにかかる時間（ミリ秒）

  let startTime;

  function easeOutExpo(t, b, c, d) {
    return c * (-Math.pow(2, -10 * t / d) + 1) * 1024 / 1023 + b;
  }

  function animation(currentTime) {
    if (startTime === undefined) {
      startTime = currentTime;
    }
    const timeElapsed = currentTime - startTime;
    const scrollPos = easeOutExpo(timeElapsed, startPos, distance, duration);
    window.scrollTo(0, scrollPos);
    if (timeElapsed < duration) {
      requestAnimationFrame(animation);
    } else {
      window.scrollTo(0, position);
    }
  }

  requestAnimationFrame(animation);
}

// 別ページ遷移後のスムーススクロール
// ハッシュを取得
const urlHash = window.location.hash;
// ハッシュが存在する時
if (urlHash) {
  // スクロール先を取得
  const target = document.getElementById(urlHash.slice(1));
  // スクロール先が存在する時
  if (target) {
    // ページトップから開始（ブラウザ差異を考慮して併用）
    history.replaceState(null, '', window.location.pathname); // ハッシュを削除
    window.scrollTo(0, 0); // ページトップへジャンプ

    // 遅延読み込み解除
    //removeLazyLoad();
    // ページを読み込んで処理
    window.addEventListener("load", () => {
      // スクロール先の位置を計算（ヘッダーの高さを引く）
      const position = target.getBoundingClientRect().top + window.scrollY - headerHeight;
      // スクロール実行
      scrollToPos(position);

      // ハッシュを再設定
      history.replaceState(null, '', window.location.pathname + urlHash);
    });
  }
}