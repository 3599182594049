(function(){
  const pagetop = document.getElementById('jsPagetop');

  // ない場合はなにもしない
  if( ! pagetop ) return;

  const pagetopActiveClass = 'pagetop-active';

  // Scroll event
  window.addEventListener('scroll', function(e){
    if (window.scrollY > 100) {
      pagetop.classList.add(pagetopActiveClass);
    } else {
      pagetop.classList.remove(pagetopActiveClass);
    }
  });
  // Click event
  pagetop.addEventListener('click', function(){
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  });
}());
